import { useState } from 'react';

import {
  DELIVERY_DESTINATION_ID,
  DELIVERY_ORDER_TYPE,
  IN_STORE_DESTINATION_ID,
} from '../constants';
import { IGetCartPayload } from '../models/order.model';
import { getCart } from '../react-query-hooks/Cart/useGetCartItems';
import { clearCart, updateCartOrder } from '../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../redux/store/store';

const useSyncCart = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const third_party_id = useAppSelector((state) => state.checkout.ezCaterId);

  const cart = useAppSelector((state) => state.cart);
  const isEditOrder = useAppSelector((state) => state.cart.is_edit_order);

  const [loading, setLoading] = useState<boolean>(false);

  const syncCart = async (location_id: string, persistOffer?: boolean) => {
    setLoading(true);
    try {
      if (!user.id) return;
      const payload: IGetCartPayload = {
        location_id: location_id,
        customer_id: user.id,
      };

      if (cart.orderType) {
        payload.order_type =
          cart.orderType === DELIVERY_ORDER_TYPE
            ? DELIVERY_DESTINATION_ID
            : IN_STORE_DESTINATION_ID;
      }
      if(isEditOrder) {
        payload.persist_offer = true;
        payload.order_id = third_party_id ? cart?.orderId : null;
      }
      if (persistOffer) {
        payload.persist_offer = true;
      }
      const response = await getCart(payload);
      if (response && response.items) {
        await dispatch(updateCartOrder(response));
      } else {
        await dispatch(clearCart());
      }
      setLoading(false);
    } catch (error) {
      await dispatch(clearCart());
      console.log(error);
      setLoading(false);
    }
  };
  return { syncCart, loading };
};

export default useSyncCart;
