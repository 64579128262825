import { createSlice } from '@reduxjs/toolkit';
import { appliedPaymentMethods } from 'src/helper/checkout/PaymentHelper';

import {
  floorNumberToDecimal,
  roundToTwo,
} from '../../priceCalculation/helper';

const initialStateValues = {
  paymentMethods: [],
  giftCard: [],
  isHouseAccountAndCZDollarSplitEnable: false,
  isSplitPayment: false,
  defaultGiftCard: {
    name: null,
    id: null,
    balance: null,
  },
  deliveryTipObject: {
    type: '%',
    tip: 0,
  },
  houseAccountObject: {
    valueObject: {
      label: '',
      value: '',
    },
    houseAccountToggle: false,
  },
  taxExemptAccountObject: {
    valueObject: {
      label: '',
      value: '',
    },
    taxExemptAccountToggle: false,
  },
  sendPaymentRequestEmail: [],
  sendPaymentRequestPhone: [],
  payInStoreToggle: false,
  textToPayToggle: false,
  totalAmount:0,
  PONumber: '',
  ezCaterId: null,
  ezCaterSource: null,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: initialStateValues,
  reducers: {
    addPaymentMethod: (state, action) => {
      state.paymentMethods.push(action.payload);
    },
    addGiftCard: (state, action) => {
      state.giftCard.push(action.payload);
    },
    updateDeliveryTipObject: (state, action) => {
      state.deliveryTipObject = action.payload;
    },
    updatePaymentCard: (state, action) => {
      state.paymentMethods = action.payload;
    },
    updateGiftCard: (state, action) => {
      state.giftCard = action.payload;
    },
    updatePaymentCardAmount: (state, action) => {
      if (state.paymentMethods.length > 0) {
        const duplicatePayments = [...state.paymentMethods];
        duplicatePayments[action.payload.index].amount =
          action.payload.amountAdded;
        state.paymentMethods = duplicatePayments;
      }
    },
    updateGiftCardAmount: (state, action) => {
      if (state.giftCard.length > 0) {
        const duplicateGiftCards = [...state.giftCard];
        duplicateGiftCards[action.payload.index].amount =
          action.payload.amountAdded;
        state.giftCard = duplicateGiftCards;
      }
    },
    updateIsSplitPayment: (state, action) => {
      state.isSplitPayment = action.payload;
    },
    updateAmountOnSplit: (state, action) => {
      const totalCards = appliedPaymentMethods(
        state.paymentMethods,
        state.giftCard,
      );
      const lastNumber = action.payload?.totalAmount?.toString();
      const isOddAmount =
        Number(lastNumber[lastNumber.length - 1]) % 2 != 0 &&
        totalCards.length > 0;
      const isRounded = false;
      const paymentCards = [...state.paymentMethods];
      const giftCards = [...state.giftCard];
      const devidedAmount = floorNumberToDecimal(
        action.payload.totalAmount / totalCards.length,
      );
      // if one gift card is selected for Split. This code will verify the card balance
      if (totalCards.length > 0) {
        let giftCardAmount = 0;
        giftCards.map((card) => {
          giftCardAmount =
            state.defaultGiftCard?.balance >= devidedAmount
              ? devidedAmount
              : state.defaultGiftCard?.balance;
          card.amount = giftCardAmount;
        });
        paymentCards.map((card) => {
          if (!giftCards?.length) {
            card.amount = devidedAmount;
          } else {
            card.amount = roundToTwo(
              action.payload.totalAmount - giftCardAmount,
            );
          }
        });
      }
      //if total amount was odd number this code will make it round to two deicmel
      if (isOddAmount && state.defaultGiftCard?.balance >= devidedAmount) {
        if (paymentCards.length > 0)
          paymentCards[0].amount = roundToTwo(
            action.payload.totalAmount / totalCards.length,
          );
        else
          giftCards[0].amount = roundToTwo(
            action.payload.totalAmount / totalCards.length,
          );
      }
      state.paymentMethods = paymentCards;
      state.giftCard = giftCards;
    },
    updateDefaultGiftCard: (state, action) => {
      state.defaultGiftCard = action.payload;
    },
    updatePayInStoreToggle: (state, action) => {
      state.payInStoreToggle = action.payload;
    },
    updateTextToPayToggle: (state, action) => {
      state.textToPayToggle = action.payload;
    },
    updateSendPaymentRequestEmail: (state, action) => {
      state.sendPaymentRequestEmail = action.payload;
    },
    updateSendPaymentRequestPhone: (state, action) => {
      state.sendPaymentRequestPhone = action.payload;
    },
    updateTotalAmount: (state, action) => {
      state.totalAmount = action.payload;
    },
    updateHouseAccount: (state, action) => {
      state.houseAccountObject = {
        ...state.houseAccountObject,
        valueObject: action.payload.valueObject,
        houseAccountToggle: action.payload.houseAccountToggle,
      };
    },
    updateTaxExemptAccount: (state, action) => {
      state.taxExemptAccountObject = {
        ...state.taxExemptAccountObject,
        valueObject: action.payload.valueObject,
        taxExemptAccountToggle: action.payload.taxExemptAccountToggle,
      };
    },
    updateHouseAccountAndCZDollarSplitEnabler: (state, action) => {
      state.isHouseAccountAndCZDollarSplitEnable = action.payload;
    },
    updatePONumber: (state, action) => {
      state.PONumber = action.payload;
    },
    updateEzCaterId: (state, action) => {
      state.ezCaterId = action.payload;
    },
    updateEzCaterSource: (state, action) => {
      state.ezCaterSource = action.payload;
    },
    resetCheckout: () => initialStateValues,
  },
});

export const {
  addPaymentMethod,
  addGiftCard,
  updatePaymentCard,
  updateDeliveryTipObject,
  updateGiftCard,
  updatePaymentCardAmount,
  updateAmountOnSplit,
  updateGiftCardAmount,
  resetCheckout,
  updateIsSplitPayment,
  updateDefaultGiftCard,
  updateHouseAccount,
  updatePayInStoreToggle,
  updateTextToPayToggle,
  updateTaxExemptAccount,
  updateTotalAmount,
  updateSendPaymentRequestEmail,
  updateSendPaymentRequestPhone,
  updateHouseAccountAndCZDollarSplitEnabler,
  updatePONumber,
  updateEzCaterId,
  updateEzCaterSource
} = checkoutSlice.actions;

export const checkoutReducer = checkoutSlice.reducer;
