import {
  setIsDeliveryToggle,
  setLocation,
  setLocationOrderTypes,
} from 'src/Features/Location/redux/slice';
import { useRedirectToSpecificStep } from 'src/hooks/useRedirectToSpecificStep';
import {
  updateCartOrderDetails,
  updateCartWithDraft,
  updateOrderType,
} from 'src/redux/slices/cartSlice';
import { setPrimaryCapacityDraft } from 'src/redux/slices/handleStatesForSpecificFlows';
import { setDraftSteps } from 'src/redux/slices/wizardSlice';
// import { useAppDispatch } from 'src/redux/store/store';
import {
  updateDeliveryTipObject,
  updateEzCaterId,
  updateGiftCard,
  updateHouseAccount,
  updateIsSplitPayment,
  updatePaymentCard,
  updatePONumber,
  updateTaxExemptAccount,
} from 'src/redux/slices/checkoutSlice';
import * as CONSTANT from 'src/constants';
import {
  updateRecievingUserData,
  updateUserData,
} from 'src/Features/AccountSetup/redux/slices';
// import { useEffect } from 'react';
import { getVisitorId } from './helperMethods';

export const saveDratLoad = ({
  dispatch,
  data,
  visitorId,
  redirect,
  draftData,
}) => {
  const {
    receiving_customer = {},
    delivery_address,
    location_details,
    primary_capacity,
    pickup_slot,
    tip,
    payment,
    wizard,
    customer_id,
    order_type,
    search_by_value,
    location_order_types,
    ez_cater_id
  } = draftData;

  const convertTo12HourFormat = (timeString) => {
    if (!timeString?.length) return '';
    let [hours, minutes, seconds] = timeString.split(':').map(Number);
    const period = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return `${hours}:${minutes} ${period}`;
  };

  localStorage.setItem(
    CONSTANT.CURRENT_USER,
    JSON.stringify({ ...data, visitor_id: visitorId }),
  );
  dispatch(updateUserData(data));
  dispatch(
    updateRecievingUserData(receiving_customer ? receiving_customer : {}),
  );

  dispatch(
    setLocation({
      currentLocation: location_details,
      zipCode: search_by_value || '',
    }),
  );
  dispatch(
    setLocationOrderTypes({
      delivery: location_order_types.delivery,
      pickUp: location_order_types.pick_up,
    }),
  );
  dispatch(updatePONumber(payment?.po_number))
  dispatch(updateOrderType(order_type));
  dispatch(setDraftSteps(wizard));
  dispatch(
    setPrimaryCapacityDraft({
      guest: primary_capacity.number_of_guest,
      date: pickup_slot.order_business_date,
      primaryCapacityItem: primary_capacity.primary_capacity_item,
      timeValue: pickup_slot.order_promise_time,
      timeLabel: convertTo12HourFormat(pickup_slot.order_promise_time),
    }),
  );
  //MAY BE NEEDED IN FUTURE
  // dispatch(
  //   updateCartOrderDetails({
  //     time: pickup_slot.order_promise_time,
  //     date: pickup_slot.order_business_date,
  //     pickupMethod: pickup_slot.order_pickup_method,
  //     ...(!!delivery_address.full_address && {
  //       deliveryAddress: {
  //         ...delivery_address,
  //         address_name: payment.address_name,
  //       },
  //     }),
  //   }),
  // );

  if (payment?.cart_details?.items) {
    payment.cart_details.items = [];
  }
  dispatch(updateCartWithDraft(payment.cart_details));
  if (payment?.delivery_toggle) {
    dispatch(setIsDeliveryToggle());
  }

  dispatch(
    updatePaymentCard([
      ...(payment?.payment_method ? payment?.payment_method : []),
    ]),
  );
  dispatch(updateGiftCard([...(payment?.gift_card ? payment?.gift_card : [])]));
  dispatch(updateIsSplitPayment(payment?.is_split_payment));
  dispatch(
    updateHouseAccount({
      valueObject: payment?.house_account?.valueObject,
      houseAccountToggle: payment?.house_account?.houseAccountToggle,
    }),
  );
  dispatch(
    updateTaxExemptAccount({
      valueObject: payment?.tax_exempt_account?.valueObject,
      taxExemptAccountToggle:
        payment?.tax_exempt_account?.taxExemptAccountToggle,
    }),
  );
  dispatch(
    updateDeliveryTipObject({
      type: tip.tip_type,
      tip: Number(tip.tip_amount) || '',
    }),
  );
  dispatch(updateEzCaterId(ez_cater_id));
  redirect(wizard.last_active_step as any);
  localStorage.removeItem('draftOrderId');
};
