import { createSlice } from '@reduxjs/toolkit';

import { PICK_UP_ORDER_TYPE } from '../../constants';

const initialStateValues = {
  items: [],
  recentlyDeletedId: null,
  index: 0,
  showCart: false,
  orderId: null,
  orderType: PICK_UP_ORDER_TYPE,
  orderDetails: null,
  isOrderTypeSelected: null,
  checkoutDetails: null,
  isLogoutAction: false,
  tax: null,
  offerDiscount: 0,
  offerDiscountType: null,
  user_offer_id: null,
  root_offer_id: null,
  redirect_offer_id: null,
  eligible_item_offer_id: null,
  root_offer_unredemeed: false,
  discount: 0,
  is_edit_order: false,
  free_delivery: false,
  edit_cart_id: null
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialStateValues,
  reducers: {
    addItemToOrder: (state, action) => {
      state.items = [...state.items, ...action.payload];
    },
    updateItemToOrder: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      state.items[index] = action.payload;
    },
    updateCartOrder: (state, action) => {
      state.items = action.payload.items;
      state.orderId = action.payload.order_id;
      state.tax = action.payload.tax;
      state.discount = action.payload.discount;
      state.is_edit_order = action.payload.is_edit_order;
      state.user_offer_id = action.payload.user_offer_id;
      state.root_offer_id = action.payload.root_offer_id;
      const shouldRemoveOfferDiscount =
        state.offerDiscount &&
        (!action.payload.user_offer_id || !action.payload.root_offer_id);
      if (shouldRemoveOfferDiscount) {
        state.offerDiscount = 0;
        state.offerDiscountType = null;
        state.free_delivery = false;
      }
      state.edit_cart_id = action?.payload?.id;
    },

    updateRootOffer: (state, action) => {
      state.root_offer_id = action.payload.root_offer_id;
      state.user_offer_id = action.payload.user_offer_id;
    },

    clearCart: (state) => {
      state.items = [];
      state.is_edit_order = false;
      state.free_delivery = false;
    },
    deleteItemFromOrder: (state, action) => {
      state.recentlyDeletedId = state.items[action.payload]?.id ?? null;
      state.items.splice(action.payload, 1);
    },
    updateItemQuantity: (state, action) => {
      const { quantity, index } = action.payload;
      state.items[index].quantity = quantity;
    },
    incrementIndex: (state) => {
      state.index++;
    },
    setShowCart: (state, action) => {
      state.showCart = action.payload;
      if (!action.payload && state.redirect_offer_id) {
        state.redirect_offer_id = null;
      }
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },

    updateRootOfferUnredemeed: (state, action) => {
      state.root_offer_unredemeed = action.payload;
    },

    updateOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    updateOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    updatePickUpMethod: (state, action) => {
      state.orderDetails.PickupSlotDetails.pickupMethod = action.payload;
    },
    updateIsOrderTypeSelected: (state, action) => {
      state.isOrderTypeSelected = action.payload;
    },
    updateCheckoutDetails: (state, action) => {
      state.checkoutDetails = action.payload;
    },
    resetCartItems: (state) => {
      state.items = [];
      state.is_edit_order = false;
      state.edit_cart_id = null;
    },
    resetOrderDetail: (state) => {
      state.orderDetails = null;
    },
    updateCartOrderDetails: (state, action) => {
      state.orderDetails = {
        ...(action.payload?.deliveryAddress?.full_address && {
          delivery_address: {
            ...state.orderDetails?.delivery_address,
            ...action.payload?.deliveryAddress,
            zipCode: action.payload?.deliveryAddress.zip_code,
            zipcode: action.payload?.deliveryAddress.zip_code,
            isDefault: action.payload?.deliveryAddress.is_default,
          },
        }),
        PickupSlotDetails: {
          ...state?.orderDetails?.PickupSlotDetails,
          date: action.payload.date,
          time: action.payload.time,
          pickupMethod: action.payload.pickupMethod || null,
        },
      };
    },
    updateCartOrderDetailsWithoutSlots: (state, action) => {
      state.orderDetails = {
        PickupSlotDetails: {
          ...state.orderDetails.PickupSlotDetails,
          pickupMethod: action?.payload?.pickupMethod,
        },
        delivery_address: '',
        vehicleDetails: '',
      };
    },
    updateCartWithDraft: (state, action) => {
      Object.assign(state, action.payload);
    },
    resetCart: (state) => {
      state.items = [];
      state.recentlyDeletedId = null;
      state.index = 0;
      state.showCart = false;
      state.orderId = null;
      state.orderDetails = null;
      state.checkoutDetails = null;
      state.isLogoutAction = false;
      state.orderType = PICK_UP_ORDER_TYPE;
      state.discount = 0;
      state.is_edit_order = false;
      state.free_delivery = false;
      state.offerDiscountType = null;
      state.edit_cart_id = null;
    },
    isLogoutAction: (state) => {
      state.isLogoutAction = !state.isLogoutAction;
    },
    updateCartItem: (state, action) => {
      state.items[action.payload.index] = action.payload.item;
    },
    updateItemKey: (state, action) => {
      const { index, key, value } = action.payload;
      state.items[index][key] = value;
    },
    updateItemPointsRedeem: (state, action) => {
      const { redemption_by_points, index } = action.payload;
      state.items[index].redemption_by_points = redemption_by_points;
    },
    updateCartRedeemedOfferItem: (state, action) => {
      if (action.payload.is_redeemed_by_offer) {
        state.items[action.payload.index].is_redeemed_by_offer =
          action.payload.is_redeemed_by_offer;
        state.items[action.payload.index].redeemed_by_offer_discount =
          action.payload.redeemed_by_offer_discount;
        state.user_offer_id = action.payload.user_offer_id;
        state.root_offer_id = action.payload.root_offer_id;
        state.eligible_item_offer_id = action.payload.eligible_item_offer_id;
        state.offerDiscount = action.payload.redeemed_by_offer_discount;
        state.offerDiscountType = action.payload.offer_discount_type;
        state.free_delivery = action.payload.free_delivery;
      } else {
        const items = [...state.items];
        items.map((item) => {
          if (item.is_redeemed_by_offer) {
            item.is_redeemed_by_offer = false;
            item.redeemed_by_offer_discount = 0;
          }
        });
        state.items = items;
        state.user_offer_id = null;
        state.root_offer_id = null;
        state.eligible_item_offer_id = null;
        state.offerDiscount = 0;
        state.offerDiscountType = null;
        state.free_delivery = false;
      }
      state.redirect_offer_id = null;
    },
    updateRedirectOfferId: (state, action) => {
      state.redirect_offer_id = action.payload.redirect_offer_id;
    },
    resetCartOffer: (state) => {
      state.offerDiscount = 0;
      state.offerDiscountType = null;
      state.user_offer_id = null;
      state.root_offer_id = null;
      state.eligible_item_offer_id = null;
      state.free_delivery = false;
    },
    clearCartOfferAuxData: (state) => {
      state.user_offer_id = null;
      state.root_offer_id = null;
      state.eligible_item_offer_id = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addItemToOrder,
  clearCart,
  isLogoutAction,
  updateItemToOrder,
  updateItemQuantity,
  updateCartOrder,
  updateRootOffer,
  updateRootOfferUnredemeed,
  deleteItemFromOrder,
  incrementIndex,
  setShowCart,
  setOrderId,
  resetCart,
  updateOrderDetails,
  updateOrderType,
  updateIsOrderTypeSelected,
  updatePickUpMethod,
  updateCheckoutDetails,
  updateCartItem,
  resetCartItems,
  updateCartRedeemedOfferItem,
  clearCartOfferAuxData,
  resetCartOffer,
  resetOrderDetail,
  updateItemPointsRedeem,
  updateItemKey,
  updateRedirectOfferId,
  updateCartOrderDetails,
  updateCartWithDraft,
  updateCartOrderDetailsWithoutSlots,
} = cartSlice.actions;

export const reducer = cartSlice.reducer;
